import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { isMobileFun, getPrefixField, createFilePath, convertStylesStringToObject, isVideo } from '../../utils/obj-utils';
import Detailss from '../../elements/InnerPageDetail';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields, setItemIndex } = props;
  const swiperRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [btnindex, setBtnindex] = useState(0);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;
  const tabsswiperRef = useRef(null);
  const containerRef = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    if (props.eventRef) {
      return {
        sendEvent
      };
    }
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };
  return (
    <>
      <div className="price_exterior_v2" id={id}>
        <div className="hash"></div>
        {fields[`${getPrefixField('toptitle', isMobile)}`]?.value && (
          <div
            className="toptitle"
            style={convertStylesStringToObject(fields[`${getPrefixField('toptitle', isMobile)}`]?.value)}
            dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('toptitle', isMobile)}`]?.value?.replaceHtml() }}
          ></div>
        )}
        <div className="con" ref={containerRef}>
          {fields?.lists.length > 0 && (
            <>
              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                className="swiper"
                spaceBetween={0}
                initialSlide={0}
                observer={true}
                resizeObserver={true}
                updateOnWindowResize={true}
                modules={[Pagination, Navigation]}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                  disabledClass: 'disabled'
                }}
                onSwiper={(swiper) => {
                  setTimeout(() => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                    swiper.navigation.destroy();
                    swiper.navigation.init();
                    swiper.navigation.update();
                  });
                }}
                onSlideChangeTransitionEnd={(e) => {
                  if (tabsswiperRef.current) {
                    tabsswiperRef.current.swiper.slideTo(e.activeIndex);
                    var allBoxes = tabsswiperRef.current.swiper.slides;
                    for (var i = 0; i < allBoxes.length; i++) {
                      allBoxes[i].className = allBoxes[i].className.replace('on', ' ');
                      allBoxes[e.activeIndex].classList.add('on');
                    }
                  }
                  setSwiperIndex(e.activeIndex);
                  setItemIndex && setItemIndex(e.activeIndex);
                }}
              >
                {fields?.lists.map((item, index) => {
                  return item?.fields[`${getPrefixField('bg', isMobile)}`]?.value || item?.fields[`${getPrefixField('bghtml', isMobile)}`]?.value ? (
                    <SwiperSlide className="item" key={index}>
                      {item?.fields[`${getPrefixField('bg', isMobile)}`]?.value && (
                        <>
                          {isVideo(item?.fields[`${getPrefixField('bg', isMobile)}`]?.value) ? (
                            <video
                              className="bg"
                              style={convertStylesStringToObject(item?.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
                              autoPlay="autoPlay"
                              muted="muted"
                              loop="loop"
                              src={createFilePath(item?.fields[`${getPrefixField('bg', isMobile)}`]?.value)}
                            ></video>
                          ) : (
                            <img
                              className="bg"
                              style={convertStylesStringToObject(item?.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
                              src={createFilePath(item?.fields[`${getPrefixField('bg', isMobile)}`]?.value)}
                              alt="广汽本田"
                            />
                          )}
                        </>
                      )}
                      {item?.fields[`${getPrefixField('bghtml', isMobile)}`]?.value && (
                        <div
                          style={convertStylesStringToObject(item?.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
                          dangerouslySetInnerHTML={{
                            __html: item?.fields[`${getPrefixField('bghtml', isMobile)}`]?.value?.replaceHtml()
                          }}
                        ></div>
                      )}
                      <div className="in">
                        {item?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                          <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
                        )}
                        <div
                          className="bottom"
                          style={convertStylesStringToObject(item?.fields[`${getPrefixField('bottomstyle', isMobile)}`]?.value)}
                        >
                          {item?.fields?.btnlists.length > 0 &&
                            item?.fields?.btnlists.map((btnitem, btnindex) => {
                              return (
                                <React.Fragment key={btnindex}>
                                  {(!btnitem?.fields?.isonly?.value ||
                                    (btnitem?.fields?.isonly?.value &&
                                      ((btnitem?.fields?.isonly?.value === 'pc' && !isMobile) ||
                                        (btnitem?.fields?.isonly?.value === 'mb' && isMobile)))) && (
                                    <div
                                      className={`btn_v1 btn_v1_${btnindex + 1}`}
                                      key={btnindex}
                                      style={convertStylesStringToObject(btnitem?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                                      dangerouslySetInnerHTML={{
                                        __html: btnitem?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml()
                                      }}
                                      onClick={() => {
                                        setFlag(true);
                                        setBtnindex(btnindex);
                                        window.Dmp.clickAction(btnitem?.fields?.dmp?.value, createFilePath(btnitem?.fields?.videourl?.value));
                                      }}
                                    ></div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </div>
                    </SwiperSlide>
                  ) : (
                    ''
                  );
                })}
              </Swiper>
              <div
                className="next"
                ref={navigationNextRef}
                data-html-item={fields?.nextdmp?.value}
                style={convertStylesStringToObject(fields[`${getPrefixField('nexticonstyle', isMobile)}`]?.value)}
              >
                <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
              </div>
              <div
                className="prev"
                ref={navigationPrevRef}
                data-html-item={fields?.prevdmp?.value}
                style={convertStylesStringToObject(fields[`${getPrefixField('previconstyle', isMobile)}`]?.value)}
              >
                <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
              </div>
              {!fields?.isbottomtabs?.value && !fields[`${getPrefixField('isnopagination', isMobile)}`]?.value && (
                <div className={`swiper-pagination ${fields?.paginationclass?.value}`}>
                  {fields?.lists.map((item, index) => {
                    return item?.fields[`${getPrefixField('bg', isMobile)}`]?.value ||
                      item?.fields[`${getPrefixField('bghtml', isMobile)}`]?.value ? (
                      <span
                        key={index}
                        className={`swiper-pagination-bullet ${swiperIndex === index ? 'swiper-pagination-bullet-active' : ''}`}
                        onClick={() => {
                          swiperslideTo(index);
                          window.Dmp.clickAction(fields?.paginationdmp?.value ? fields?.paginationdmp?.value + (index + 1) : '');
                        }}
                      ></span>
                    ) : (
                      ''
                    );
                  })}
                </div>
              )}
            </>
          )}
          {fields?.lists.length > 0 && fields?.isbottomtabs?.value && (
            <div
              className={`tabs tabs${fields?.lists.length}`}
              style={convertStylesStringToObject(fields[`${getPrefixField('bottomtabsstyle', isMobile)}`]?.value)}
            >
              <Swiper
                ref={tabsswiperRef}
                slidesPerView={isMobile ? 2 : 3}
                className="tabsswiper"
                spaceBetween={0}
                initialSlide={0}
                observer={true}
                resizeObserver={true}
                updateOnWindowResize={true}
                navigation={false}
                centeredSlides={isMobile && swiperIndex !== 0 ? true : false}
              >
                {fields?.lists.map((item, index) => {
                  return item?.fields[`${getPrefixField('bg', isMobile)}`]?.value || item?.fields[`${getPrefixField('bghtml', isMobile)}`]?.value ? (
                    <SwiperSlide
                      className={swiperIndex === index ? 'item on' : 'item'}
                      key={index}
                      onClick={() => {
                        swiperslideTo(index);
                        window.Dmp.clickAction(item?.fields?.bottomtabsdmp?.value);
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}></div>
                    </SwiperSlide>
                  ) : (
                    ''
                  );
                })}
              </Swiper>
            </div>
          )}
        </div>
        {flag && (
          <Detailss
            routePath={fields?.lists[swiperIndex]?.fields?.btnlists[btnindex]?.fields?.detailurl?.value}
            {...fields?.lists[swiperIndex]?.fields?.btnlists[btnindex]?.fields}
            close={() => setFlag(false)}
          />
        )}
      </div>
    </>
  );
}
