import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { getPrefixField, isMobileFun, createFilePath, convertStylesStringToObject } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index({ fields }) {
  const isMobile = isMobileFun();
  const swiperRef = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };
  return (
    <div className="InnerPageLists_v1" style={convertStylesStringToObject(fields[`${getPrefixField('constyle', isMobile)}`]?.value)}>
      <div className="con">
        {fields?.lists.length > 0 &&
          fields?.lists.map((item, subindex) => {
            return (
              <div
                key={subindex}
                className="item"
                style={convertStylesStringToObject(item?.fields[`${getPrefixField('itemstyle', isMobile)}`]?.value)}
              >
                {item?.fields[getPrefixField('img', isMobile)]?.value && (
                  <img
                    alt="广汽本田"
                    className="img"
                    style={convertStylesStringToObject(item?.fields[`${getPrefixField('imgstyle', isMobile)}`]?.value)}
                    src={`${createFilePath(item?.fields[getPrefixField('img', isMobile)]?.value)}`}
                  />
                )}
                {item?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                  <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
                )}
                {item?.fields?.thirdlists.length > 0 && (
                  <div className="sublists">
                    <Swiper
                      ref={swiperRef}
                      slidesPerView={isMobile ? 1 : 3}
                      className="swiper"
                      spaceBetween={isMobile ? 0 : 70}
                      initialSlide={0}
                      observer={true}
                      resizeObserver={true}
                      updateOnWindowResize={true}
                      modules={[Pagination, Navigation]}
                      navigation={{
                        prevEl: '.InnerPageLists_v1 .sublists .prev',
                        nextEl: '.InnerPageLists_v1 .sublists .next',
                        disabledClass: 'disabled'
                      }}
                      onInit={(e) => {}}
                      onSlideChangeTransitionEnd={(e) => {
                        setSwiperIndex(e.activeIndex);
                      }}
                    >
                      {item?.fields?.thirdlists.map((thirditem, thirdindex) => {
                        return (
                          <SwiperSlide className="thirditem" key={thirdindex}>
                            {thirditem?.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                              <div
                                dangerouslySetInnerHTML={{ __html: thirditem?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}
                              ></div>
                            )}
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <div className="next" data-html-item={fields?.nextdmp?.value}>
                      <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
                    </div>
                    <div className="prev" data-html-item={fields?.prevdmp?.value}>
                      <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
                    </div>
                    {!item?.fields[`${getPrefixField('isnopagination', isMobile)}`]?.value && (
                      <div className={`swiper-pagination ${item?.fields?.paginationclass?.value}`}>
                        {item?.fields?.thirdlists.map((navitem, index) => {
                          return (
                            <span
                              key={index}
                              className={`swiper-pagination-bullet ${swiperIndex === index ? 'swiper-pagination-bullet-active' : ''}`}
                              onClick={() => {
                                swiperslideTo(index);
                                window.Dmp.clickAction(item?.fields?.paginationdmp?.value ? item?.fields?.paginationdmp?.value + (index + 1) : '');
                              }}
                            ></span>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}
