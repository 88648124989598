import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun, convertStylesStringToObject, getPrefixField } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import Price_exterior_v2 from '../Price_exterior_v2';
import './style.scss';

export default function Banner(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const [tabIndex, setTabIndex] = useState(1);
  const [ItemIndex, setItemIndex] = useState(0);
  const [tabsflag, setTabsflag] = useState(true);
  const id = props?.params?.pagename;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const thirdPageRef = useRef(null);
  const thirdPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;

  // sectionAction
  useEffect(() => {
    if (
      (fields?.placeHolderRoute?.value && !fields?.placeHolderRoute1?.value) ||
      (!fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value)
    ) {
      props.updateRefList([sendFirstDmp, sendSecondDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current]);
    } else if (fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value) {
      props.updateRefList([sendFirstDmp, sendSecondDmp, sendThirdDmp]);
      props.updateDmpList([rootEl?.current, secondPageRef?.current, thirdPageRef?.current]);
    } else {
      props.updateRefList([sendFirstDmp]);
      props.updateDmpList([rootEl?.current]);
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  const sendThirdDmp = () => {
    thirdPageEventRef?.current?.sendEvent();
  };
  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      fields?.tabs.map((item, index) => {
        let tabArr = item.fields?.tabname?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.tabsdefault?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const tabClick = (item, index) => {
    setTabsflag(false);
    setTimeout(() => {
      setTabsflag(true);
    }, 10);
    setTabIndex(index);
    setItemIndex(0);
    window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
  };
  const getItemIndex = (index) => {
    setItemIndex(index);
  };
  return (
    <div id={id}>
      <div className="Price_exteriorTabs_v2" ref={rootEl}>
        {fields?.tabs && fields?.tabs.length > 1 && (
          <div
            className={`tabs tabs${fields?.tabs.length}`}
            style={convertStylesStringToObject(
              fields?.tabs[tabIndex]?.fields?.lists[ItemIndex]?.fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value
                ? fields?.tabs[tabIndex]?.fields?.lists[ItemIndex]?.fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value
                : fields?.tabs[tabIndex]?.fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value
                ? fields?.tabs[tabIndex]?.fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value
                : fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value
            )}
          >
            {fields?.tabs.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`item ${index === tabIndex ? 'on' : ''}`}
                    onClick={() => tabClick(item, index)}
                    dangerouslySetInnerHTML={{ __html: item.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                  ></div>
                </React.Fragment>
              );
            })}
          </div>
        )}
        {fields?.tabs.length > 0 && tabsflag && fields?.tabs[tabIndex]?.fields && (
          <Price_exterior_v2 fields={fields?.tabs[tabIndex]?.fields} setItemIndex={getItemIndex}></Price_exterior_v2>
        )}
      </div>
      {fields?.placeHolderRoute?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={fields?.placeHolderRoute?.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
      {fields?.placeHolderRoute1?.value && (
        <div ref={thirdPageRef}>
          <Placeholder name={fields?.placeHolderRoute1?.value} rendering={route} page={route} eventRef={thirdPageEventRef} />
        </div>
      )}
    </div>
  );
}
