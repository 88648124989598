import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMobileFun, getPrefixField, createFilePath, convertStylesStringToObject } from '../../utils/obj-utils';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import Detailss from '../../elements/InnerPageDetail';
import 'swiper/swiper.min.css';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const [flag, setFlag] = useState(false);
  const [detailflag, setDetailflag] = useState(false);
  const swiperRef = useRef(null);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [swiperListsIndex, setSwiperListsIndex] = useState(0);
  const [bglistsIndex, setBglistsIndex] = useState(0);
  const [btnindex, setBtnindex] = useState(0);
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;
  const rootEl = useRef(null);
  const secondPageRef = useRef(null);
  const secondPageEventRef = useRef(null);
  const thirdPageRef = useRef(null);
  const thirdPageEventRef = useRef(null);
  const route = useSitecoreContext()?.sitecoreContext?.route;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  // sectionAction
  useEffect(() => {
    if (id) {
      if (
        (fields?.placeHolderRoute?.value && !fields?.placeHolderRoute1?.value) ||
        (!fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value)
      ) {
        props.updateRefList([sendFirstDmp, sendSecondDmp]);
        props.updateDmpList([rootEl?.current, secondPageRef?.current]);
      } else if (fields?.placeHolderRoute?.value && fields?.placeHolderRoute1?.value) {
        props.updateRefList([sendFirstDmp, sendSecondDmp, sendThirdDmp]);
        props.updateDmpList([rootEl?.current, secondPageRef?.current, thirdPageRef?.current]);
      } else {
        props.updateRefList([sendFirstDmp]);
        props.updateDmpList([rootEl?.current]);
      }
    }
  }, []);
  const sendFirstDmp = () => {
    window.Dmp.sectionAction(id);
    // 二级导航高亮所属变量
    window.navigationIndex = fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  const sendSecondDmp = () => {
    secondPageEventRef?.current?.sendEvent();
  };
  const sendThirdDmp = () => {
    thirdPageEventRef?.current?.sendEvent();
  };
  const windowhidden = () => {
    document.documentElement.style.overflow = 'hidden';
  };
  const closewindowhidden = () => {
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.overflowX = 'hidden';
  };
  const swiperslideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };
  return (
    <div id={id}>
      <div className="price_space_v1" ref={rootEl}>
        <div className="hash"></div>
        <div className="con">
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            className="lists_swiper"
            spaceBetween={0}
            initialSlide={0}
            observer={true}
            resizeObserver={true}
            updateOnWindowResize={true}
            modules={[Pagination, Navigation]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
              disabledClass: 'disabled'
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              });
            }}
            onSlideChangeTransitionEnd={(e) => {
              setSwiperListsIndex(e.activeIndex);
            }}
          >
            {fields?.lists.map((item, index) => {
              return (
                <SwiperSlide className="item" key={index}>
                  {item.fields[`${getPrefixField('bg', isMobile)}`]?.value && (
                    <img
                      className="bg"
                      style={convertStylesStringToObject(item.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
                      src={createFilePath(item.fields[`${getPrefixField('bg', isMobile)}`]?.value)}
                      alt="广汽本田"
                    />
                  )}
                  <div className="in">
                    {item.fields[`${getPrefixField('text', isMobile)}`]?.value && (
                      <div dangerouslySetInnerHTML={{ __html: item.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
                    )}
                    <div className="bottom" style={convertStylesStringToObject(item?.fields[`${getPrefixField('bottomstyle', isMobile)}`]?.value)}>
                      {item?.fields?.btnlists.length > 0 &&
                        item?.fields?.btnlists.map((btnitem, btnindex) => {
                          return (
                            <React.Fragment key={btnindex}>
                              {(!btnitem?.fields?.isonly?.value ||
                                (btnitem?.fields?.isonly?.value &&
                                  ((btnitem?.fields?.isonly?.value === 'pc' && !isMobile) ||
                                    (btnitem?.fields?.isonly?.value === 'mb' && isMobile)))) && (
                                <>
                                  {btnitem?.fields?.openurl ? (
                                    <div
                                      className={`btn_v1 btn_v1_${btnindex + 1}`}
                                      key={btnindex}
                                      style={convertStylesStringToObject(btnitem?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                                      dangerouslySetInnerHTML={{
                                        __html: btnitem?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml()
                                      }}
                                      onClick={() => {
                                        window.open(btnitem?.fields?.openurl?.value);
                                        window.Dmp.clickAction(
                                          btnitem?.fields?.dmp?.value,
                                          btnitem?.fields?.openurl?.value ? btnitem?.fields?.openurl?.value : ''
                                        );
                                      }}
                                    ></div>
                                  ) : (
                                    <div
                                      className={`btn_v1 btn_v1_${btnindex + 1}`}
                                      key={btnindex}
                                      style={convertStylesStringToObject(btnitem?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                                      dangerouslySetInnerHTML={{
                                        __html: btnitem?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml()
                                      }}
                                      onClick={() => {
                                        setDetailflag(true);
                                        setBtnindex(btnindex);
                                        window.Dmp.clickAction(
                                          btnitem?.fields?.dmp?.value,
                                          btnitem?.fields?.videourl?.value ? createFilePath(btnitem?.fields?.videourl?.value) : ''
                                        );
                                      }}
                                    ></div>
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                    </div>
                    {item.fields?.lists.length > 0 && (
                      <div className="control">
                        {item.fields?.lists.map((dotitem, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                window.Dmp.clickAction(
                                  dotitem?.fields?.dotdmp?.value,
                                  dotitem?.fields?.dmpname?.value ? dotitem?.fields?.dmpname?.value : ''
                                );
                                setSwiperIndex(index);
                                windowhidden();
                                setFlag(true);
                              }}
                              className={`dot dot${index + 1}`}
                              style={convertStylesStringToObject(dotitem?.fields[`${getPrefixField('dotstyle', isMobile)}`]?.value)}
                            ></div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div
            className="lists_next"
            ref={navigationNextRef}
            data-html-item={fields?.nextdmp?.value ? fields?.nextdmp?.value : ''}
            style={convertStylesStringToObject(fields[`${getPrefixField('nexticonstyle', isMobile)}`]?.value)}
          >
            {fields[`${getPrefixField('nexticon', isMobile)}`]?.value && (
              <img className="icon" src={createFilePath(fields[`${getPrefixField('nexticon', isMobile)}`]?.value)} alt="广汽本田" />
            )}
          </div>
          <div
            className="lists_prev"
            ref={navigationPrevRef}
            data-html-item={fields?.prevdmp?.value ? fields?.prevdmp?.value : ''}
            style={convertStylesStringToObject(fields[`${getPrefixField('previconstyle', isMobile)}`]?.value)}
          >
            {fields[`${getPrefixField('previcon', isMobile)}`]?.value && (
              <img className="icon" src={createFilePath(fields[`${getPrefixField('previcon', isMobile)}`]?.value)} alt="广汽本田" />
            )}
          </div>
          {!fields[`${getPrefixField('isnopagination', isMobile)}`]?.value && (
            <div className={`lists_swiper-pagination ${fields?.paginationclass?.value}`}>
              {fields?.lists.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={`swiper-pagination-bullet ${swiperListsIndex === index ? 'swiper-pagination-bullet-active' : ''}`}
                    onClick={() => {
                      swiperslideTo(index);
                      window.Dmp.clickAction(fields?.paginationdmp?.value ? fields?.paginationdmp?.value + (index + 1) : '');
                    }}
                  ></span>
                );
              })}
            </div>
          )}
        </div>
        {flag && (
          <>
            <div className="prop_detail">
              <Swiper
                ref={swiperRef}
                slidesPerView={1}
                spaceBetween={0}
                initialSlide={swiperIndex}
                observer={true}
                resizeObserver={true}
                updateOnWindowResize={true}
                modules={[Navigation]}
                navigation={{
                  prevEl: '.price_space_v1 .prop_detail .prev',
                  nextEl: '.price_space_v1 .prop_detail .next',
                  disabledClass: 'disabled'
                }}
                onInit={(e) => {}}
                onTransitionStart={(e) => {}}
                onSlideChangeTransitionEnd={(e) => {}}
              >
                {fields?.lists[swiperListsIndex]?.fields?.lists.map((item, index) => {
                  return (
                    <SwiperSlide className="item" key={index}>
                      <div
                        className="close"
                        onClick={() => {
                          setFlag(false);
                          closewindowhidden();
                          window.Dmp.clickAction(item?.fields?.closedmp?.value, item?.fields?.dmpname?.value ? item?.fields?.dmpname?.value : '');
                        }}
                        style={convertStylesStringToObject(
                          item?.fields[`${getPrefixField('closestyle', isMobile)}`]?.value
                            ? item?.fields[`${getPrefixField('closestyle', isMobile)}`]?.value
                            : fields?.lists[swiperListsIndex]?.fields[`${getPrefixField('closestyle', isMobile)}`]?.value
                        )}
                      >
                        {item?.fields[`${getPrefixField('closeicon', isMobile)}`]?.value ? (
                          <img
                            className="icon"
                            src={createFilePath(item?.fields[`${getPrefixField('closeicon', isMobile)}`]?.value)}
                            alt="广汽本田"
                          />
                        ) : (
                          <img
                            className="icon"
                            src={createFilePath(fields?.lists[swiperListsIndex]?.fields[`${getPrefixField('closeicon', isMobile)}`]?.value)}
                            alt="广汽本田"
                          />
                        )}
                      </div>
                      {item?.fields?.bglists.length > 0 ? (
                        <>
                          {item?.fields?.bglists[bglistsIndex]?.fields[`${getPrefixField('bg', isMobile)}`]?.value && (
                            <img
                              className="bg"
                              style={convertStylesStringToObject(
                                item?.fields?.bglists[bglistsIndex]?.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value
                              )}
                              src={createFilePath(item?.fields?.bglists[bglistsIndex]?.fields[`${getPrefixField('bg', isMobile)}`]?.value)}
                              alt="广汽本田"
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {item?.fields[`${getPrefixField('bg', isMobile)}`]?.value && (
                            <img
                              className="bg"
                              style={convertStylesStringToObject(item?.fields[`${getPrefixField('bgstyle', isMobile)}`]?.value)}
                              src={createFilePath(item?.fields[`${getPrefixField('bg', isMobile)}`]?.value)}
                              alt="广汽本田"
                            />
                          )}
                        </>
                      )}
                      <div className="in">
                        {item?.fields[`${getPrefixField('text')}`]?.value && (
                          <div dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('text', isMobile)}`]?.value?.replaceHtml() }}></div>
                        )}
                        {item?.fields?.bglists.length > 0 && (
                          <div className="bglistcir">
                            {item?.fields?.bglists.map((bgitem, bgindex) => {
                              return (
                                <div
                                  key={bgindex}
                                  className={`item item${bgindex + 1} ${bglistsIndex === bgindex ? 'on' : ''}`}
                                  style={convertStylesStringToObject(bgitem?.fields[`${getPrefixField('iconstyle', isMobile)}`]?.value)}
                                  onClick={() => {
                                    setBglistsIndex(bgindex);
                                    window.Dmp.clickAction(
                                      bgitem?.fields?.dmp?.value,
                                      bgitem?.fields?.dmpname?.value ? bgitem?.fields?.dmpname?.value : ''
                                    );
                                  }}
                                ></div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div
                className="next"
                style={convertStylesStringToObject(fields?.lists[swiperListsIndex]?.fields[`${getPrefixField('nexticonstyle', isMobile)}`]?.value)}
                data-html-item={fields?.lists[swiperListsIndex]?.fields?.nextdmp?.value}
              >
                <img
                  className="icon"
                  src={createFilePath(fields?.lists[swiperListsIndex]?.fields[`${getPrefixField('nexticon', isMobile)}`]?.value)}
                  alt="广汽本田"
                />
              </div>
              <div
                className="prev"
                style={convertStylesStringToObject(fields?.lists[swiperListsIndex]?.fields[`${getPrefixField('previconstyle', isMobile)}`]?.value)}
                data-html-item={fields?.lists[swiperListsIndex]?.fields?.prevdmp?.value}
              >
                <img
                  className="icon"
                  src={createFilePath(fields?.lists[swiperListsIndex]?.fields[`${getPrefixField('previcon', isMobile)}`]?.value)}
                  alt="广汽本田"
                />
              </div>
            </div>
          </>
        )}
      </div>
      {detailflag && (
        <Detailss
          routePath={fields?.lists[swiperListsIndex]?.fields?.btnlists[btnindex]?.fields?.detailurl?.value}
          close={() => setDetailflag(false)}
        />
      )}
      {fields?.placeHolderRoute?.value && (
        <div ref={secondPageRef}>
          <Placeholder name={fields?.placeHolderRoute?.value} rendering={route} page={route} eventRef={secondPageEventRef} />
        </div>
      )}
      {fields?.placeHolderRoute1?.value && (
        <div ref={thirdPageRef}>
          <Placeholder name={fields?.placeHolderRoute1?.value} rendering={route} page={route} eventRef={thirdPageEventRef} />
        </div>
      )}
    </div>
  );
}
