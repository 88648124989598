import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { dataFetcher } from '../../dataFetcher';
import { convertStylesStringToObject, getPrefixField, isMobileFun, createFilePath, GotoMall, scrollToAnchor } from '../../utils/obj-utils';

let jsApiListArr = ['updateAppMessageShareData', 'updateTimelineShareData'];
let wx = null;
export const prepareWechatSdk = () => {
  if (!wx) {
    wx = require('weixin-js-sdk');
  }
  return wx;
};
export default function ViceHeadernav(props) {
  if (!props?.showFooter) {
    return null;
  }
  const { fields } = props;
  const [meauscroll, setMeauscroll] = useState(true);
  const showmeauRef = useRef(null);
  const sublogoRef = useRef(null);
  const [mobileshowmeau, setMobileshowmeau] = useState(false);
  const [mobileexpand, setMobileexpand] = useState(false);
  const isMobile = isMobileFun();
  const [num, setNum] = useState(0);
  useEffect(() => {
    // 微信分享
    const w = prepareWechatSdk();
    setWxShare(jsApiListArr, w, () => {
      w.ready(() => {
        w.updateAppMessageShareData({
          title: fields.WXtitle.value,
          desc: fields.WXdesc.value,
          link: location.href,
          imgUrl: createFilePath(fields.WXimg.value)
        });
        w.updateTimelineShareData({
          title: fields.WXtitle.value,
          link: location.href,
          imgUrl: createFilePath(fields.WXimg.value)
        });
        let wxVideo = document.querySelectorAll('video');
        document.addEventListener('WeixinJSBridgeReady', function () {
          for (let i = 0; i < wxVideo.length(); i++) {
            wxVideo[i].play();
          }
        });
      });
    });
    function setWxShare(jsApiListArr, wx, Callback) {
      if (window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i) !== 'micromessenger') {
        return;
      }
      dataFetcher('/api/honda/v1/DWP/GetDWPWeChatShareData?shareUrl=' + encodeURIComponent(location.href), null)
        .then((res) => {
          const { data } = res;
          wx.config({
            debug: false,
            appId: data.Data.appId,
            timestamp: data.Data.timestamp,
            nonceStr: data.Data.noncestr,
            signature: data.Data.signature,
            jsApiList: jsApiListArr,
            openTagList: ['wx-open-launch-weapp']
          });
          wx.ready(Callback);
        })
        .catch((err) => {});
    }
    return () => {};
  }, []);

  // 事件监听器，用于关闭打开的二级导航
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showmeauRef.current && !showmeauRef.current.contains(event.target) && !sublogoRef.current.contains(event.target)) {
        setMobileshowmeau(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    const scroll = () => {
      const t = document.documentElement.scrollTop || document.body.scrollTop;
      setMeauscroll(!(t >= 60));
    };
    window.addEventListener('scroll', scroll);
    Object.defineProperty(window, 'navigationIndex', {
      set(navigationIndex) {
        setNum(navigationIndex);
      },
      configurable: true
    });
    if (window.navigationIndex === undefined) {
      window.navigationIndex = 0;
    }
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  return (
    <div
      className={`Subnav_v1 ViceHeadernavBox_v2 ${!meauscroll ? 'active' : ''}`}
      style={convertStylesStringToObject(fields[`${getPrefixField('navstyle', isMobile)}`]?.value)}
    >
      {fields[`${getPrefixField('sublogo', isMobile)}`]?.value && (
        <div
          className="sublogo"
          style={convertStylesStringToObject(fields[`${getPrefixField('sublogostyle', isMobile)}`]?.value)}
          ref={sublogoRef}
          onClick={() => {
            setMobileshowmeau(!mobileshowmeau);
            window.Dmp.decideAction(fields?.mobileshowmeaudmp?.value, !mobileshowmeau);
          }}
        >
          <div className="top">
            <img className="icon" src={createFilePath(fields[`${getPrefixField('sublogo', isMobile)}`]?.value)} alt="广汽本田" />
            {fields[`${getPrefixField('name', isMobile)}`]?.value && (
              <div className="name" dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('name', isMobile)}`]?.value?.replaceHtml() }}></div>
            )}
          </div>
          {isMobile && (
            <img
              className={`Subnav_v1_moreicon ${mobileshowmeau ? 'on' : ''}`}
              src={createFilePath(fields[`${getPrefixField('moreicon', isMobile)}`]?.value)}
              alt="广汽本田"
            />
          )}
        </div>
      )}
      {fields?.lists.length > 0 && (!isMobile || (isMobile && mobileshowmeau)) && (
        <div
          className="Subnav_v1_navs"
          ref={showmeauRef}
          style={convertStylesStringToObject(fields[`${getPrefixField('Subnavstyle', isMobile)}`]?.value)}
        >
          {fields?.lists.map((item, index) => {
            return (
              <div className={`item ${num === index ? 'on' : ''}`} key={index}>
                {item?.fields[`${getPrefixField('title', isMobile)}`]?.value && (
                  <>
                    <div
                      className="top"
                      onMouseEnter={(e) => {
                        if (!item?.fields?.href?.value && item.fields.expand?.length > 0 && !isMobile) {
                          window.Dmp.hoverAction(item?.fields?.dmp?.value);
                        }
                      }}
                      onClick={() => {
                        if (item?.fields?.href?.value.indexOf('http') !== -1 || item?.fields?.href?.value.indexOf('/') !== -1) {
                          window.Dmp.clickAction(item?.fields?.dmp?.value, item?.fields.href?.value);
                          window.open(item?.fields.href?.value);
                          setMobileshowmeau(false);
                        } else if (item?.fields?.href?.value) {
                          window.Dmp.clickAction(item?.fields?.dmp?.value);
                          scrollToAnchor(`${item.fields.href.value}`);
                          setMobileshowmeau(false);
                          setNum(index);
                        } else {
                          if (isMobile) {
                            setMobileexpand(!mobileexpand);
                            window.Dmp.decideAction(item?.fields?.dmp?.value, !mobileexpand);
                          } else {
                            window.Dmp.clickAction(item?.fields?.dmp?.value);
                          }
                        }
                        if (isMobile) {
                          setMobileexpand(!mobileexpand);
                        }
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}></span>
                      {item.fields.expand?.length > 0 && (
                        <img
                          className={`icon ${mobileexpand ? 'on' : ''}`}
                          src={createFilePath(fields[`${getPrefixField('moreicon', isMobile)}`]?.value)}
                          alt="广汽本田"
                        />
                      )}
                      {num === index && (
                        <i className="line" style={convertStylesStringToObject(fields[`${getPrefixField('linestyle', isMobile)}`]?.value)}></i>
                      )}
                    </div>
                    {item.fields.expand?.length > 0 && (
                      <div
                        className={`expand ${mobileexpand ? 'on' : ''}`}
                        style={convertStylesStringToObject(fields[`${getPrefixField('expandstyle', isMobile)}`]?.value)}
                      >
                        {item.fields.expand?.map((subitem, subindex) => {
                          return (
                            <div
                              className="subitem"
                              key={subindex}
                              onClick={(e) => {
                                console.log(subitem?.fields?.href?.value);
                                if (subitem?.fields?.href?.value.indexOf('http') !== -1 || subitem?.fields?.href?.value.indexOf('/') !== -1) {
                                  window.Dmp.clickAction(subitem?.fields?.dmp?.value, subitem?.fields.href?.value);
                                  window.open(subitem?.fields.href?.value);
                                } else if (subitem?.fields?.href?.value) {
                                  window.Dmp.clickAction(subitem?.fields?.dmp?.value);
                                  scrollToAnchor(`${subitem.fields.href.value}`);
                                } else {
                                  window.Dmp.clickAction(subitem?.fields?.dmp?.value);
                                }
                                setMobileshowmeau(false);
                                e.stopPropagation();
                              }}
                              dangerouslySetInnerHTML={{ __html: subitem?.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                            ></div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
      {fields?.btnlists.length > 0 && (
        <div className="btnlists">
          {fields?.btnlists.map((item, index) => {
            return (
              item?.fields[`${getPrefixField('btn', isMobile)}`]?.value && (
                <div
                  className={`btn_v1 btn_v1_${index + 1}`}
                  key={index}
                  style={convertStylesStringToObject(item?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                  dangerouslySetInnerHTML={{ __html: item?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml() }}
                  onClick={() => {
                    if (item?.fields?.url?.value.indexOf('http') !== -1 || item?.fields?.url?.value.indexOf('/') !== -1) {
                      window.open(item?.fields?.url?.value);
                      window.Dmp.clickAction(item?.fields?.dmp?.value, item?.fields?.url?.value);
                    } else if (item?.fields?.url?.value) {
                      if (item?.fields?.title?.value === '立即下订') {
                        GotoMall(item?.fields?.url?.value, item?.fields?.dmp?.value, isMobile);
                      } else {
                        window.Dmp.clickAction(item?.fields?.dmp?.value);
                        scrollToAnchor(item?.fields?.url?.value);
                      }
                    } else {
                      window.Dmp.clickAction(item?.fields?.dmp?.value);
                    }
                  }}
                ></div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
}
