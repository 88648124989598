import React, { useEffect, useState, useRef, createRef, useImperativeHandle } from 'react';
import gsap from 'gsap';
import * as ScrollMagic from 'scrollmagic-with-ssr';
import Detailss from '../../elements/InnerPageDetail';
import { getPrefixField, convertStylesStringToObject, sendDmp, scrollFinish, isMobileFun, createFilePath } from '../../utils/obj-utils';
import { getQueryVariable } from '../../utils/site-utils';
import './style.scss';

export default function index(props) {
  const { fields } = props;
  const containerRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [btnindex, setBtnindex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const replaceContainerRefList = fields?.tabs[tabIndex].fields?.lists.map(() => createRef());
  const isMobile = isMobileFun();
  const id = props?.params?.pagename;

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    if (props.eventRef) {
      return {
        sendEvent
      };
    }
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };

  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      fields?.tabs.map((item, index) => {
        let tabArr = item.fields?.tabname?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.tabsdefault?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const tabClick = (item, index) => {
    // containerRef.current.scrollIntoView();
    setTabIndex(index);
    window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
    window.Dmp.trackEnable = false;

    setTimeout(() => {
      containerRef.current.scrollIntoView();
      setTimeout(() => {
        window.Dmp.trackEnable = true;
      }, 100);
    }, 0);
  };

  useEffect(() => {
    const replaceTimeline = gsap.timeline({ paused: true });
    fields?.tabs[tabIndex].fields?.lists.forEach((_, index) => {
      fields?.tabs[tabIndex].fields?.lists.length - 1 - index > 0 &&
        replaceTimeline.fromTo(
          replaceContainerRefList[fields?.tabs[tabIndex].fields?.lists.length - 1 - index].current,
          { height: isMobile ? '50vh' : '58vh' },
          { height: 0 }
        );
    });
    const replaceController = new ScrollMagic.Controller();
    const replaceScene = new ScrollMagic.Scene({
      triggerElement: containerRef.current,
      triggerHook: 'onLeave',
      duration: document.documentElement.clientHeight
    })
      .on('progress', function (event) {
        replaceTimeline.progress(event.progress);
      })
      .setPin(containerRef.current)
      .setTween(replaceTimeline)
      .addTo(replaceController);

    return () => {
      replaceTimeline.kill();
      replaceScene.remove();
      replaceScene.destroy();
      replaceController.destroy();
    };
  }, [tabIndex]);

  return (
    <div className="price_exterior_v3" id={id} ref={containerRef}>
      <div className="hash"></div>
      <div className="con" style={convertStylesStringToObject(fields[`${getPrefixField('constyle', isMobile)}`]?.value)}>
        {fields?.tabs && fields?.tabs.length > 1 && (
          <div
            className={`tabs tabs${fields?.tabs.length}`}
            style={convertStylesStringToObject(fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value)}
          >
            {fields?.tabs.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={`item ${index === tabIndex ? 'on' : ''}`}
                    onClick={() => tabClick(item, index)}
                    dangerouslySetInnerHTML={{ __html: item.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                  ></div>
                </React.Fragment>
              );
            })}
          </div>
        )}
        <div
          className="lo"
          style={convertStylesStringToObject(fields?.tabs[tabIndex].fields[`${getPrefixField('lostyle', isMobile)}`]?.value)}
          dangerouslySetInnerHTML={{ __html: fields?.tabs[tabIndex].fields[`${getPrefixField('lo', isMobile)}`]?.value?.replaceHtml() }}
        ></div>
        <div className="lists">
          {fields?.tabs[tabIndex].fields?.lists &&
            fields?.tabs[tabIndex].fields?.lists.map((item, index) => {
              return (
                <div className="item" key={index} ref={replaceContainerRefList[index]}>
                  <div className="in">
                    <div
                      className="info"
                      style={convertStylesStringToObject(item.fields[`${getPrefixField('infostyle', isMobile)}`]?.value)}
                      dangerouslySetInnerHTML={{ __html: item.fields[`${getPrefixField('info', isMobile)}`]?.value.replaceHtml() }}
                    />
                    <div className="img" style={convertStylesStringToObject(item.fields[`${getPrefixField('imgstyle', isMobile)}`]?.value)}>
                      <img alt="广汽本田" src={`${createFilePath(item.fields[`${getPrefixField('img', isMobile)}`]?.value)}`}></img>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className="tips"
          dangerouslySetInnerHTML={{ __html: fields?.tabs[tabIndex].fields[`${getPrefixField('tips', isMobile)}`]?.value?.replaceHtml() }}
        />
        <div
          className="bottom"
          style={convertStylesStringToObject(fields?.tabs[tabIndex].fields[`${getPrefixField('bottomstyle', isMobile)}`]?.value)}
        >
          {fields?.tabs[tabIndex].fields?.btnlists.length > 0 &&
            fields?.tabs[tabIndex].fields?.btnlists.map((btnitem, btnindex) => {
              return (
                <React.Fragment key={btnindex}>
                  {(!btnitem?.fields?.isonly?.value ||
                    (btnitem?.fields?.isonly?.value &&
                      ((btnitem?.fields?.isonly?.value === 'pc' && !isMobile) || (btnitem?.fields?.isonly?.value === 'mb' && isMobile)))) && (
                    <div
                      className={`btn_v1 btn_v1_${btnindex + 1}`}
                      key={btnindex}
                      style={convertStylesStringToObject(btnitem?.fields[`${getPrefixField('btnstyle', isMobile)}`]?.value)}
                      dangerouslySetInnerHTML={{
                        __html: btnitem?.fields[`${getPrefixField('btn', isMobile)}`]?.value?.replaceHtml()
                      }}
                      onClick={() => {
                        setFlag(true);
                        setBtnindex(btnindex);
                        window.Dmp.clickAction(btnitem?.fields?.dmp?.value, createFilePath(btnitem?.fields?.videourl?.value));
                      }}
                    ></div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </div>
      {flag && <Detailss routePath={fields?.tabs[tabIndex].fields?.btnlists[btnindex]?.fields?.detailurl?.value} close={() => setFlag(false)} />}
    </div>
  );
}
