import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { getQueryVariable } from '../../utils/site-utils';
import { isMobileFun, getPrefixField, convertStylesStringToObject, createFilePath, GotoMall } from '../../utils/obj-utils';
import CarComparison_v1 from '../CarComparison_v1';
import './style.scss';

export default function Banner(props) {
  const { fields } = props;
  const isMobile = isMobileFun();
  const [tabIndex, setTabIndex] = useState(0);
  const id = props?.params?.pagename;
  const [tabsflag, setTabsflag] = useState(true);

  // sectionAction
  useImperativeHandle(props.eventRef, () => {
    return {
      sendEvent
    };
  });
  const sendEvent = () => {
    window.Dmp.sectionAction(id);
    window.navigationIndex = props.fields.navigationIndex.value;
    window.navigationPagename = window.navigationPagenameb = id !== undefined ? id : '';
  };
  // tabs
  useEffect(() => {
    const queryTabDefault = (value) => {
      let defaultIndex = -1;
      fields?.tabs.map((item, index) => {
        let tabArr = item.fields?.tabname?.value.split(',').map((tItem) => tItem.toLowerCase());
        if (tabArr.includes(value)) {
          defaultIndex = index;
        }
      });
      return defaultIndex;
    };
    const timer = setTimeout(() => {
      let tab = getQueryVariable('tab');
      let defaultTabInd = queryTabDefault(fields.tabsdefault?.value?.toLowerCase());
      if (tab) {
        let tabInd = queryTabDefault(tab.toLowerCase());
        setTabIndex(tabInd < 0 ? (defaultTabInd < 0 ? tabIndex : defaultTabInd) : tabInd);
      } else {
        setTabIndex(defaultTabInd < 0 ? tabIndex : defaultTabInd);
      }
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const tabClick = (item, index) => {
    setTabIndex(index);
    setTabsflag(false);
    setTimeout(() => {
      setTabsflag(true);
    }, 10);
    if (item?.fields?.tabsdmp?.value) {
      window.Dmp.clickAction(item?.fields?.tabsdmp?.value);
    }
  };
  return (
    <div className="CarComparisonTab_v2" id={id}>
      {fields?.tabs && fields?.tabs.length > 1 && (
        <div
          className={`tabs tabs${fields?.tabs.length}`}
          style={convertStylesStringToObject(fields[`${getPrefixField('tabsstyle', isMobile)}`]?.value)}
        >
          {fields?.tabs.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  className={`item ${index === tabIndex ? 'on' : ''}`}
                  onClick={() => tabClick(item, index)}
                  dangerouslySetInnerHTML={{ __html: item.fields[`${getPrefixField('title', isMobile)}`]?.value?.replaceHtml() }}
                ></div>
              </React.Fragment>
            );
          })}
        </div>
      )}

      {fields?.tabs.map((item, index) => {
        return (
          <div className={`con ${index === tabIndex ? 'on' : ''}`} key={index}>
            <CarComparison_v1 fields={item?.fields}></CarComparison_v1>
          </div>
        );
      })}
    </div>
  );
}
