import React, { useEffect, useState } from 'react';
import './style.scss';
import temp from '../../temp/config';
import {
  getQueryString,
  getPrefixField,
  convertStylesStringToObject,
  isMobileFun,
  createFilePath,
  getPathnameByPosition
} from '../../utils/obj-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
export default function index(props) {
  const isMobile = isMobileFun();
  let scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        try {
          window.top.Dmp.isAnchor = true;
        } catch (e) {
          Dmp.isAnchor = true;
        }
        anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  };
  let scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const [menuShow, setMenuShow] = useState(true);
  const [cookieFlag, setCookieFlag] = useState(false);
  const [questionnaireFlag, setQuestionnaireFlag] = useState(false);
  var Cookie = {
    set: function (key, val, expiresDays) {
      // 判断是否设置expiresDays
      if (expiresDays) {
        var date = new Date();
        date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000); // 格式化时间
        var expiresStr = 'expires=' + date.toGMTString() + ';';
      } else {
        var expiresStr = '';
      }
      document.cookie = key + '=' + escape(val) + ';' + expiresStr;
      console.log(key + '=' + escape(val) + ';' + expiresStr);
    },
    get: function (key) {
      var getCookie = document.cookie.replace(/[ ]/g, '');
      var resArr = getCookie.split(';');
      var res;
      for (var i = 0, len = resArr.length; i < len; i++) {
        var arr = resArr[i].split('=');
        if (arr[0] === key) {
          res = arr[1];
          break;
        }
      }
      return unescape(res);
    }
  };
  var myDate = function (t) {
    // t 代表指定的参数
    if (t === undefined) {
      var Year = new Date().getFullYear(),
        s = 0,
        d; // 获取当前年
      for (var i = 1; i < 13; i++) {
        d = new Date(Year, i, 0); // 获取某一个月的天数
        s += d.getDate();
      }
      return s;
    } else if (t >= 1970) {
      var Year = new Date().getFullYear(),
        s = 0,
        d;
      for (var i = 1; i < 13; i++) {
        d = new Date(t, i, 0);
        s += d.getDate();
      }
      return s;
    } else {
      return '年份有误';
    }
  };
  useEffect(() => {
    setCookieFlag(Cookie.get('GLOBAL_COOKIEV3') !== '1' && getQueryString('source') !== 'app');
    setQuestionnaireFlag(Cookie.get('questionnaire_COOKIE') !== '1');
  }, []);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    const scroll = () => {
      if (document.documentElement.scrollTop > document.documentElement.clientHeight - 60) {
        setFlag(true);
      } else {
        setFlag(false);
      }
      const t = document.documentElement.scrollTop || document.body.scrollTop;
      setMenuShow(!(t >= 60));
    };
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);
  return (
    <>
      <div className="return_top">
        {flag ? (
          <img
            alt="广汽本田"
            style={{ zIndex: cookieFlag ? 0 : 900 }}
            data-action="clickAction"
            data-item={props.fields.returnDmp[0].name}
            className="img1"
            src={`${createFilePath(props.fields.return_icon.value)}`}
            onClick={(e) => {
              scrollTop();
            }}
          />
        ) : (
          ''
        )}
        {flag &&
        getPathnameByPosition()?.toLowerCase() !== 'accord' &&
        getPathnameByPosition()?.toLowerCase() !== 'odyssey' &&
        getPathnameByPosition()?.toLowerCase() !== 'breeze' &&
        getPathnameByPosition()?.toLowerCase() !== 'integra' &&
        getPathnameByPosition()?.toLowerCase() !== 'enp2' ? (
          <img
            alt="广汽本田"
            style={{ zIndex: cookieFlag ? 0 : 900, display: props.fields.driveFlag.fields.k.value === 'true' ? 'inline' : 'none' }}
            data-action="clickAction"
            data-item={props.fields.driveDmp[0].name}
            className="img2"
            src={`${createFilePath(props.fields.drive_icon.value)}`}
            onClick={() => scrollToAnchor('drive')}
          />
        ) : (
          ''
        )}
        {flag ? (
          <>
            {isMobile &&
              props.fields?.enterpriseList &&
              props.fields?.enterpriseList.length > 0 &&
              props.fields?.enterpriseList?.map((item, index) => {
                return (
                  item?.fields?.type?.value === getPathnameByPosition()?.toLowerCase() && (
                    <a
                      href={item?.fields?.url?.value}
                      data-html-item={item?.fields?.dmp?.value}
                      data-url={item?.fields?.url?.value}
                      target="_blank"
                      className="enterprise"
                      rel="noreferrer"
                      key={index}
                      style={convertStylesStringToObject(item?.fields?.style?.value)}
                    >
                      <img className="img" src={`${createFilePath(item?.fields?.icon?.value)}`} alt={item?.fields?.name?.value} />
                    </a>
                  )
                );
              })}
          </>
        ) : (
          ''
        )}
        {cookieFlag ? (
          <div className="index_cookieRoot_1CTdP">
            为了提供个性化的展示与改善您的浏览体验，本网站使用Cookie。继续浏览我们的网站，即表示您同意我们的
            <a
              onClick={() => {
                window.open(props.fields.cookieLink.value);
              }}
            >
              Cookie政策
            </a>
            以及
            <a
              onClick={() => {
                window.open(props.fields.privacyLink.value);
              }}
            >
              隐私政策
            </a>
            。
            <a
              className="index_btn_2xFwe"
              onClick={() => {
                setCookieFlag(false);
                Cookie.set('GLOBAL_COOKIEV3', '1', myDate());
              }}
            >
              同意
            </a>
          </div>
        ) : (
          ''
        )}
      </div>
      {/* {questionnaireFlag ? (
        <div
          className={`questionnaire ${props.fields.driveFlag.fields.k.value === 'true' ? 'questionnaire_car' : ''} ${!menuShow ? 'active' : ''}`}
          style={convertStylesStringToObject(props.fields[`${getPrefixField('questionnaire_style', isMobile)}`]?.value)}
        >
          <img alt="广汽本田" className="img" src={`${createFilePath(props.fields.questionnairecode.value)}`} />
          <p
            className="p"
            dangerouslySetInnerHTML={{ __html: props.fields[`${getPrefixField('questionnairename', isMobile)}`].value?.replaceHtml() }}
          ></p>
          <a
            className="close"
            onClick={() => {
              setQuestionnaireFlag(false);
              Cookie.set('questionnaire_COOKIE', '1', 1);
            }}
          >
            <img alt="广汽本田" src={createFilePath('/common/questionnaire/close.png')} />
          </a>
        </div>
      ) : (
        ''
      )} */}
    </>
  );
}
