import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Checkbox from '../../elements/checkbox';
import api from '../../api/api';
import Icon from '../../elements/icon';
import classNames from 'classnames';
import Swiper from 'swiper';
import { Navigation } from 'swiper';
import { nextTick } from '../../utils/dom-utils';
import Toggle from '../../elements/toggle';
import Modal from '../../elements/modal';
import config from '../../temp/config';
import temp from '../../temp/config';
import { getPrefixField, sendDmpAction, sendDmp, createFilePath } from '../../utils/obj-utils';
import './style.scss';
const CarComparison = (props) => {
  const { fields } = props;
  const rootEl = useRef(null);
  const selectionSwiperEl = useRef(null);
  const selectionSwiper = useRef(null);
  const selectionLeftEl = useRef(null);
  const selectionRightEl = useRef(null);
  const tableCarConfigEl = useRef(null);
  const [configData, setConfigData] = useState(null);
  const [configDataRender, setConfigDataRender] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [collapsedVersions, setCollapsedVersions] = useState([]);
  const [versionVisible, setVersionVisible] = useState(true);
  const [versionModalVisible, setVersionModalVisible] = useState(true);
  const [mobileCollapsedVersions, setMobileCollapsedVersions] = useState([]);
  const [collapsedProps, setCollapsedProps] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [allCarList, setAllCarList] = useState([]);
  const [allImgList, setAllImgList] = useState([]);
  const [remark, setRemark] = useState('');
  const [checkVersion, setCheckVersion] = useState(false);
  const [full, setFull] = useState(false);
  const versions = useMemo(() => configData, [configData]);
  const renderData = useMemo(() => configDataRender, [configDataRender]);
  const selectionTitleClasses = classNames('_s-car-comparison__selection-title', {
    'versions-hidden': !versionVisible
  });
  const [loading, setLoading] = useState(true);
  const [sliderOn, setSliderOn] = useState(false);
  const methods = {
    async init() {
      const carList = [];
      const selectList = [];
      const imgList = [];
      const [res, ex] = await api.vehicle.config({
        carSeries: fields.carSeries.value,
        version: fields.version.value,
        dataPath: fields.dataPath.value,
        renderCode: fields.renderCode.value
      });
      const resIndex = res.template_data.filter((item, index) => {
        if (item.render_code === fields.item_action.value) {
          item.group_child.forEach((ele) => {
            carList.push(ele);
            if (ele.default_select) {
              ele.version = item.name;
              selectList.push(ele);
            }
          });
          return res.template_data[index];
        }
      });
      const renderIndex = res.template_render.filter((item, index) => {
        if (item.rendercode === fields.item_action.value) {
          setRemark(item.group_child[item.group_child.length - 1]?.remark);
          item.group_child[item.group_child.length - 1]?.tabs?.forEach((ele) => {
            imgList.push(ele);
          });
          return res.template_render[index];
        }
      });
      setAllImgList(imgList);
      setConfigData(resIndex);
      setConfigDataRender(renderIndex);
      setAllCarList(carList);
      setSelectedOptions(selectList);
      setLoading(false);
    },
    optionChecked(op) {
      return selectedOptions.includes(op);
    },
    toggleOption(op, version) {
      let options = [...selectedOptions];
      const index = options.findIndex((x) => x === op);
      if (index >= 0) {
        options.splice(index, 1);
      } else {
        if (version) {
          op.version = version.name;
        }
        options.push(op);
      }
      sendDmpAction(op.close_dmp_point.function_name, op.close_dmp_point.id, op.name);
      setSelectedOptions(options);
      nextTick().then(() => {
        selectionSwiper.current.updateSlides();
      });
    },
    toggleOptionDmp(op, version) {
      let list = [];
      let options = [...selectedOptions];
      const index = options.findIndex((x) => x === op);
      if (index >= 0) {
        options.splice(index, 1);
        list = [...options];
        sendDmpAction(op.check_dmp_point.function_name, op.check_dmp_point.id, 'false');
      } else {
        if (version) {
          op.version = version.name;
        }
        options.push(op);
        allCarList &&
          allCarList.length > 0 &&
          allCarList.forEach((ele) => {
            if (options.filter((element) => element === ele).length > 0) {
              list.push(ele);
            }
          });
        sendDmpAction(op.check_dmp_point.function_name, op.check_dmp_point.id, 'true');
      }
      setSelectedOptions(list);
      nextTick().then(() => {
        selectionSwiper.current.updateSlides();
      });
    },
    versionClass(version) {
      return classNames('_s-car-comparison__version', {
        'no-collapsed-icon': version.group_child.length <= 4
      });
    },
    versionCollapseIconClass(version) {
      return classNames('_s-car-comparison__version-collapse', {
        collapsed: collapsedVersions.includes(version)
      });
    },
    toggleVersionCollapsed(version, i) {
      const versions = [...collapsedVersions];
      const index = versions.findIndex((x) => x === version);
      if (index >= 0) {
        sendDmp(fields.toggle_expand_dmp?.length > 1 ? [fields.toggle_expand_dmp[i]] : [fields.toggle_expand_dmp[0]], true);
        versions.splice(index, 1);
      } else {
        sendDmp(fields.toggle_expand_dmp?.length > 1 ? [fields.toggle_expand_dmp[i]] : [fields.toggle_expand_dmp[0]], false);
        versions.push(version);
      }
      setCollapsedVersions(versions);
    },
    mobileVersionCollapseIconClass(version) {
      return classNames('_s-car-comparison__versions-modal--group__collapse', {
        collapsed: collapsedVersions.includes(version)
      });
    },
    toggleMobileVersionCollapsed(version, i) {
      const versions = [...mobileCollapsedVersions];
      const index = versions.findIndex((x) => x === version);
      const list = ['detail-compare-oil', 'detail-compare-hybrid', 'detail-compare-plugin'];
      if (index >= 0) {
        versions.splice(index, 1);
        sendDmpAction(
          fields.m_toggle_expand_dmp?.length > 0 ? fields.m_toggle_expand_dmp[i].fields.func.value : 'decide',
          fields.m_toggle_expand_dmp?.length > 0 ? fields.m_toggle_expand_dmp[i].name : list[i],
          true
        );
      } else {
        versions.push(version);
        sendDmpAction(
          fields.m_toggle_expand_dmp?.length > 0 ? fields.m_toggle_expand_dmp[i].fields.func.value : 'decide',
          fields.m_toggle_expand_dmp?.length > 0 ? fields.m_toggle_expand_dmp[i].name : list[i],
          false
        );
      }
      setMobileCollapsedVersions(versions);
    },
    toggleCollapsedProp(prop, i, flag) {
      const properties = [...collapsedProps];
      const index = properties.findIndex((x) => x === prop);
      if (index >= 0) {
        properties.splice(index, 1);
        flag && sendDmpAction(prop.d_m_p__point.function_name, prop.d_m_p__point.id, 'false');
      } else {
        properties.push(prop);
        flag && sendDmpAction(prop.d_m_p__point.function_name, prop.d_m_p__point.id, 'true');
      }
      setCollapsedProps(properties);
    },
    toggleVersionVisible: useCallback(() => {
      setVersionVisible(!versionVisible);
      sendDmpAction('decide', fields.comparison_dmp?.length > 0 ? fields.comparison_dmp[0].name : '', !versionVisible);
    }, [versionVisible]),
    fieldValue(op, id) {
      let val = op.item_child.find((x) => x.param_id === id)?.param_value;
      if (!val) return null;
      if (/\[\{(.|\s)*?\}\]/gi.test(val)) {
        val = val.replace(/k:/gi, '"k":').replace(/v:/gi, '"v":').replace(/'/g, '"');
        const data = JSON.parse(val);
        return (
          <div className="_s-car-comparison__section-item-values">
            {data.map((it, index) => (
              <div className="_s-car-comparison__section-item-value" key={index}>
                <p>{it.k}</p>
                <img alt="广汽本田" src={createFilePath(it.v)} />
              </div>
            ))}
          </div>
        );
      } else {
        return val;
      }
    },
    areValuesSame(arr) {
      if (arr) {
        return arr.every((value) => value === arr[0]);
      } else {
        return arr;
      }
    },
    fieldValueLists(it, j) {
      let newattr = [];
      selectedOptions.forEach((op, k) => {
        newattr.push(methods.fieldValue(op, it.param_id));
      });
      if (methods.areValuesSame(newattr) && sliderOn && newattr.length > 1) {
        return '';
      } else {
        return (
          <div className="_s-car-comparison__section-item" key={j}>
            <div
              className="_s-car-comparison__section-item-name"
              dangerouslySetInnerHTML={{ __html: it.name?.replace(/\\r\\n|\r\n/g, '<br/>')?.replaceHtml() }}
            ></div>
            <div className="_s-car-comparison__section-item-dl">
              <div className="_s-car-comparison__section-item-dl-box">
                {selectedOptions.map((op, k) => (
                  <div className="_s-car-comparison__section-item-data" key={k}>
                    {methods.fieldValue(op, it.param_id)}
                  </div>
                ))}
              </div>
            </div>
            <div className="_s-car-comparison__section-item-space" />
          </div>
        );
      }
    },
    fieldValueListsChildren(ele, index) {
      let newattr = [];
      selectedOptions.forEach((op, k) => {
        newattr.push(methods.fieldValue(op, ele.param_id));
      });
      if (methods.areValuesSame(newattr) && sliderOn && newattr.length > 1) {
        return '';
      } else {
        return (
          <div className="_s-car-comparison__section" key={index}>
            <div className="_s-car-comparison__section-header" style={{ justifyContent: 'center', fontWeight: 700, backgroundColor: '#EFEFEF' }}>
              <div className="_s-car-comparison__section-title">{ele.name}</div>
            </div>
            <div className="_s-car-comparison__section-items">
              {ele.item_child.map((it, j) => (
                <div className="_s-car-comparison__section-item" key={j}>
                  <div className="_s-car-comparison__section-item-name">{it.name}</div>
                  <div className="_s-car-comparison__section-item-dl">
                    <div className="_s-car-comparison__section-item-dl-box">
                      {selectedOptions.map((op, k) => (
                        <div className="_s-car-comparison__section-item-data" key={k}>
                          {methods.fieldValue(op, it.param_id)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="_s-car-comparison__section-item-space" />
                </div>
              ))}
            </div>
          </div>
        );
      }
    }
  };
  useEffect(() => {
    setIsMobile(global.innerWidth < 1024);
    setVersionModalVisible(fields[`${getPrefixField('show_version_modal')}`]?.value);
    methods.init().then();
    selectionSwiper.current = new Swiper(selectionSwiperEl.current, {
      observer: true,
      slidesPerView: 'auto',
      freeMode: true,
      modules: [Navigation],
      navigation: {
        prevEl: selectionLeftEl.current,
        nextEl: selectionRightEl.current
      },
      on: {
        transitionEnd(swiper) {
          rootEl.current.querySelectorAll('._s-car-comparison__section-item-dl-box').forEach((it) => {
            it.style.transform = swiper.$wrapperEl.css('transform');
          });
        },
        slidePrevTransitionStart() {
          sendDmp(fields.comparison_left_dmp);
        },
        slideNextTransitionStart() {
          sendDmp(fields.comparison_right_dmp);
        }
      }
    });
    return () => {
      selectionSwiper.current.destroy(true, true);
    };
  }, []);
  useEffect(() => {
    if (configDataRender) {
      let group_child1 = configDataRender[0].group_child[1];
      if (group_child1) {
        methods.toggleCollapsedProp(group_child1, 1, false);
      }
    }
  }, [configDataRender]);
  useEffect(() => {
    const sectionContainerEl = document.querySelector('._s-car-comparison__sectionContainer');
    if (sectionContainerEl && !isMobile) {
      sectionContainerEl.addEventListener('scroll', (e) => {
        if (e.target.scrollTop > 10) {
          setCheckVersion(true);
          setVersionVisible(false);
          tableCarConfigEl.current.style.height = '73vh';
        } else {
          setCheckVersion(false);
          setVersionVisible(true);
          tableCarConfigEl.current.style.height = '58vh';
        }
      });
    }
  }, [loading]);

  useEffect(() => {
    let sum = 0;
    if (versions && selectedOptions) {
      for (let i = 0; i < versions.length; i++) {
        sum = sum + versions[i].group_child.length;
      }

      setFull(sum === selectedOptions.length);
    }
  }, [versions, selectedOptions]);

  return (
    <div className="_s-car-comparison" ref={rootEl}>
      {!loading && versionVisible && !isMobile && (
        <div className="_s-car-comparison__versions">
          {versions &&
            versions.map((version, i) => (
              <div className={methods.versionClass(version)} key={i}>
                <div className="_s-car-comparison__version-header">{version.name}</div>
                <div className="_s-car-comparison__options">
                  {version.group_child.map((op, j) => {
                    if (collapsedVersions.includes(version) && j > 3) {
                      return <React.Fragment key={j} />;
                    } else {
                      return (
                        <div className="_s-car-comparison__option" key={j}>
                          <Checkbox label={op.name} checked={methods.optionChecked(op)} onChange={() => methods.toggleOptionDmp(op, version)} />
                        </div>
                      );
                    }
                  })}
                </div>
                {version.group_child.length > 4 && (
                  <Icon
                    name="ArrowTopThin"
                    className={methods.versionCollapseIconClass(version)}
                    onClick={(e) => methods.toggleVersionCollapsed(version, i)}
                  />
                )}
              </div>
            ))}
        </div>
      )}
      <div className="_s-car-comparison__selection">
        {!loading && (
          <div className="_s-car-comparison__selection-header">
            <div className={selectionTitleClasses}>
              <p>{fields.comparison_text.value}</p>
              <Icon
                name="ArrowTopDouble"
                onClick={(e) => {
                  methods.toggleVersionVisible(e);
                }}
              />
            </div>
            <div className="_s-car-comparison__selection-name">
              <p>
                <strong>{fields.car_zh.value}</strong>
                <br />
                {fields.car_en.value}
              </p>
            </div>
            <button
              data-action="clickAction"
              data-item={fields.comparison_dmp.length > 0 ? fields.comparison_dmp[0].name : ''}
              className="_s-car-comparison__selection-title-m"
              onClick={(e) => {
                setVersionModalVisible(true);
              }}
            >
              {fields.comparison_text.value}
            </button>
            <p className="compare-checkout-box">
              只显示不同
              <span className={`slider${sliderOn ? ' on' : ''}`} onClick={() => setSliderOn(!sliderOn)}>
                <input type="checkbox" />
              </span>
            </p>
          </div>
        )}
        <div className="_s-car-comparison__selection-left" ref={selectionLeftEl}>
          <Icon name="ArrowLeft" />
        </div>
        <div className="_s-car-comparison__selection-items">
          <div className="swiper" ref={selectionSwiperEl}>
            <div className="swiper-wrapper">
              {selectedOptions.map((op, index) => (
                <div className="swiper-slide" key={index}>
                  <div className="_s-car-comparison__selection-slide-close" onClick={(e) => methods.toggleOption(op, '', index)}>
                    ×
                  </div>
                  <div className="_s-car-comparison__selection-slide-name">{op.name}</div>
                  <div className="_s-car-comparison__selection-slide-version">{op.version}</div>
                  <div className="_s-car-comparison__selection-slide-price">
                    {op.item_child[0].param_value}
                    <br />
                    <span>{fields.price_type.value}</span>
                  </div>
                </div>
              ))}
              {isMobile && allCarList && allCarList.length > 0 && selectedOptions && selectedOptions.length < allCarList.length && (
                <div className="swiper-slide addVersionDiv">
                  <img
                    alt="广汽本田"
                    className="addVersion"
                    src={createFilePath(fields.m_addVersion_icon.value)}
                    onClick={() => {
                      sendDmp(fields.m_addVersion_dmp);
                      setVersionModalVisible(true);
                    }}
                  />
                </div>
              )}
              {!isMobile && checkVersion && !full && (
                <div className="swiper-slide addVersionDiv">
                  <img
                    alt="广汽本田"
                    className="addVersion_pc"
                    src={createFilePath(versionVisible ? fields.checkVersion_icon.value : fields.addVersion_black_icon.value)}
                    onClick={() => {
                      sendDmp(fields.m_addVersion_dmp);
                      setVersionVisible(true);
                      setCheckVersion(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="_s-car-comparison__selection-right" ref={selectionRightEl}>
          <Icon name="ArrowRight" />
        </div>
      </div>
      {!loading && (
        <div className="tableCarConfig" ref={tableCarConfigEl}>
          <div className="_s-car-comparison__sectionContainer">
            <div className="_s-car-comparison__sections">
              {renderData &&
                renderData[0].group_child.map((r, i) => {
                  if (i === 0 || i === renderData[0].group_child.length - 1) {
                    return <React.Fragment key={i} />;
                  }
                  return (
                    <div className="_s-car-comparison__section" key={i}>
                      <div className="_s-car-comparison__section-header">
                        <Toggle onClick={(e) => methods.toggleCollapsedProp(r, i, true)} expanded={collapsedProps.includes(r)} />
                        <div className="_s-car-comparison__section-title">{r.name}</div>
                      </div>
                      <div className="_s-car-comparison__section-items" style={{ display: collapsedProps.includes(r) ? 'block' : 'none' }}>
                        {r.item_child.map((it, j) => methods.fieldValueLists(it, j))}
                        {r.group_child && r.group_child.length > 0 && r.group_child.map((ele, index) => methods.fieldValueListsChildren(ele, index))}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="_carTabBox">
              <div dangerouslySetInnerHTML={{ __html: fields[`${getPrefixField('tab_title')}`]?.value?.replaceHtml() }} />
              <div className="carTab">
                {allImgList.map((ele, index) => {
                  return (
                    <div
                      key={index}
                      className={tabIndex === index ? 'active' : ''}
                      onClick={() => {
                        sendDmpAction(ele.dmp__point?.function_name, ele.dmp__point?.id);
                        setTabIndex(index);
                      }}
                    >
                      {ele.title}
                    </div>
                  );
                })}
              </div>
              <div className="carContext">
                {/* <img alt="广汽本田" src={`${config.app.deployUrlS3}${allImgList[tabIndex]?.image}`} alt={allImgList[tabIndex]?.title} /> */}
                {allImgList[tabIndex]?.image && (
                  <>
                    {allImgList[tabIndex]?.image.indexOf(',') !== -1 ? (
                      <>
                        {allImgList[tabIndex]?.image.split(',').map((item, index) => {
                          return <img key={index} src={createFilePath(item)} alt={allImgList[tabIndex]?.title} />;
                        })}
                      </>
                    ) : (
                      <img src={createFilePath(allImgList[tabIndex]?.image)} alt={allImgList[tabIndex]?.title} />
                    )}
                  </>
                )}
              </div>
            </div>
            {remark && <div className="_s-car-comparison__help" dangerouslySetInnerHTML={{ __html: remark?.replaceHtml() }}></div>}
          </div>
        </div>
      )}
      {!loading && fields[`${getPrefixField('show_close')}`]?.value && (
        <div className="_s-car-comparison__close">
          <img
            alt="广汽本田"
            data-action={fields.close_dmp[0].fields.func.value}
            data-item={fields.close_dmp[0].name}
            // src={`${temp.app.deployUrlS3}${fields.close_icon.value}`}
            src={createFilePath(fields.close_icon.value)}
            onClick={() => {
              props.close();
            }}
          />
        </div>
      )}
      <Modal className="_s-car-comparison__versions-modal" open={versionModalVisible && isMobile}>
        <div className="_s-car-comparison__versions-modal--header">
          <p>选择版本</p>
          <Icon
            name="Close"
            className="_s-car-comparison__versions-modal--close"
            onClick={(e) => {
              sendDmpAction(fields.close_comparison_dmp[0].fields.func.value, fields.close_comparison_dmp[0].name, '');
              setVersionModalVisible(false);
            }}
          />
        </div>
        <div className="_s-car-comparison__versions-modal--groups">
          {versions &&
            versions.map((version, index) => (
              <div className="_s-car-comparison__versions-modal--group" key={index}>
                <div
                  className="_s-car-comparison__versions-modal--group__header"
                  onClick={(e) => methods.toggleMobileVersionCollapsed(version, index)}
                >
                  <p>{version.name}</p>
                  <Icon name="ArrowTopThin" className={methods.mobileVersionCollapseIconClass(version)} />
                </div>
                {!mobileCollapsedVersions.includes(version) && (
                  <div className="_s-car-comparison__versions-modal--group__options">
                    {version.group_child.map((op, j) => (
                      <div className="_s-car-comparison__versions-modal--group__option" key={j}>
                        <Checkbox label={op.name} checked={methods.optionChecked(op)} onChange={() => methods.toggleOptionDmp(op, version)} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};
export default CarComparison;
